import { createStore } from 'vuex';
import * as auth from './modules/auth';
import * as books from './modules/books';
import * as users from './modules/users';

export default createStore({
  state: {
    isModal: false,
    modal: {
      title: '',
      name: '',
      text: '',
      action: '',
      button: '',
      alert: '',
      disabled: true
    }
  },
  mutations: {
    SET_MODAL (state, value) {
      state.isModal = value;
    },
    SET_ACTION_MODAL (state, modal) {
      state.modal = { ...state.modal, ...modal };
    }
  },
  modules: {
    auth,
    books,
    users
  }
});
