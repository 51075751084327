import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './store/jsMethodsOverride';
import './styles/helper-classes.scss';
import './styles/typography.scss';
import './styles/grid.scss';
import './styles/base.scss';
import BaseInput from '@/components/BaseInput';
import BaseTable from '@/components/BaseTable';
import BaseModal from '@/components/BaseModal';
import BaseActionModal from '@/components/BaseActionModal';
import BaseSearchBar from '@/components/BaseSearchBar';
import BaseFileUpload from '@/components/BaseFileUpload';
import BaseSpinner from '@/components/BaseSpinner';
import BaseAudioFile from '@/components/BaseAudioFile';
import BaseConfirmationModal from '@/components/BaseConfirmationModal';
import BaseBooksListNav from '@/components/BaseBooksListNav.vue';

const app = createApp(App);
app.component('BaseInput', BaseInput);
app.component('BaseTable', BaseTable);
app.component('BaseModal', BaseModal);
app.component('BaseActionModal', BaseActionModal);
app.component('BaseSearchBar', BaseSearchBar);
app.component('BaseFileUpload', BaseFileUpload);
app.component('BaseSpinner', BaseSpinner);
app.component('BaseAudioFile', BaseAudioFile);
app.component('BaseConfirmationModal', BaseConfirmationModal);
app.component('BackToBooks', BaseBooksListNav);
app.use(store).use(router).mount('#app');
