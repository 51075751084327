<template>
  <div class="file-upload relative" :cy="cy || name">
    <div v-if="preview || value" class="flex flex-between">
      <span><b cy="book-file-name">{{ fileName }}</b></span>
      <img
        v-if="!value"
        cy="remove-icon"
        src="../assets/delete-icon.svg"
        @click="handleRemove"
      />
    </div>

    <label v-else class="flex-center relative wh100" :for="name">
      {{ label }}
      <input class="absolute" :id="name" @change="handleFile" type="file" />
    </label>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    name: String,
    cy: String,
    label: String,
    value: String,
    id: String
  });
  const emit = defineEmits(['upload', 'remove']);
  const preview = ref(false);
  const fileName = ref(props.value);

  function handleFile (ev) {
    preview.value = true;
    fileName.value = ev.target.files[0].name;
    emit('upload', { name: props.name, file: ev.target.files[0] });
  }

  function handleRemove () {
    emit('remove', { name: props.name });
  }
</script>

<style lang="scss">
  .file-upload {
    margin-top: 16px;
    label {
      cursor: pointer;
      padding: 6px 0;
      border: 1px solid gray;
      border-radius: 4px;
      //background-color: lightgray;
      font-size: 1.8rem;
    }
    input {
      visibility: hidden;
      width: 0;
      height: 0.1px;
    }
  }
</style>
