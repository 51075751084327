<template>
  <div class="base-action-modal relative" cy="action-modal">
    <img
      src="../assets/close-icon-round.svg"
      cy="close-icon"
      class="close-icon absolute pointer"
      @click="handleClose"
      alt="close icon"
    />
    <h2>{{ modal.title }}</h2>
    <p v-if="modal.text">{{ modal.text }}</p>
    <slot />
    <p v-if="modal.alert">{{ modal.alert }}</p>
    <button
        v-if="modal.action !== 'sending'"
      cy="action-modal-btn"
      class="action-btn green-btn m-t-16"
      :disabled="modal.disabled"
      @click="handleClick"
    >
      {{ modal.button }}
    </button>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    name: String
  });

  import { useStore } from 'vuex';

  const { state, commit } = useStore();
  const modal = computed(() => state.modal);

  function handleClose () {
    commit('SET_MODAL', false);
    emits('close', { name: props.name });
  }

  const emits = defineEmits(['click', 'close']);

  function handleClick () {
    emits('click', { name: props.name, action: modal.value.action });
  }
</script>

<style lang="scss">
@import "../styles/vars";
  .base-action-modal {
    @media only screen and (min-width: 0) {
      .close-icon {
        top: -40px;
        right: -40px;
      }
      .action-btn {

      }
    }
  }
</style>
