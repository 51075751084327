<template>
  <router-link class="bold"
               to="/books" cy="books-list-btn">
    <img src="../assets/arrow-back.svg" alt="arrow back" />
    Books
  </router-link>
</template>

<script setup>

</script>

<style lang="scss">

</style>
