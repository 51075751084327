const admin = 'admin';
const user = 'user';
import booksIcon from '../assets/books-icon.jpg';
import dashboardIcon from '../assets/chart-icon.png';

export const menu = [
  { route: 'dashboard', name: 'Dashboard', roles: [admin], icon: dashboardIcon  },
  { route: 'books', name: 'Books', roles: [admin, user], icon: booksIcon }
];

export const userCreatedText =
  'An email was sent to the user with account confirmation and password.';
export const userExistsText = 'The email address is already registered.';
export const serverErrorText = 'There was an error executing the request.';
export const userUpdatedText = 'User status has been updated.';
export const userDeleteAlertText =
  'The user will be deleted on save. Be careful, this action cannot be undone.';
export const invalidLogin = 'Username or password invalid.';

export const validationMsgs = {
  required: 'This field is mandatory.'
};
