import axios from 'axios';
import api from './api';
import {serverErrorText, userCreatedText, userExistsText} from '@/store/data'

export const state = function () {
  return {
    data: [],
    status: 'idle'
  };
};

export const mutations = {
  SET_USER_STATE (state, { key, value }) {
    state[key] = value;
  },
  ADD_USER (state, email) {
    state.data.push({ email, status: 'active' });
  },
  UPDATE_USER_STATUS (state, { email, status }) {
    const index = state.data.findIndex((o) => o.email === email);
    state.data[index].status = status;
  },
  DELETE_USER (state, { email, status }) {
    const index = state.data.findIndex((o) => o.email === email);
    state.data.splice(index, 1);
  }
};

export const actions = {
  async get_users ({ commit }) {
    try {
      const { data } = await axios(api.users);
      commit('SET_USER_STATE', { key: 'data', value: data });
    } catch (err) {}
  },
  async create_user ({ commit }, email) {
    try {
      commit('SET_ACTION_MODAL', {action: 'sending'});
      await axios.post(api.users, { email });
      let payload = {
        title: 'User created',
        text: userCreatedText,
        button: 'OK',
        action: '',
        disabled: false
      };
      commit('SET_ACTION_MODAL', payload);
      commit('SET_USER_STATE', {key: 'status', value: 'idle'})
      commit('ADD_USER', email);
    } catch ({ response }) {
      commit('SET_USER_STATE', {key: 'status', value: 'error'})
      if (response?.status === 409) {
        let payload = {
          title: 'Error',
          text: userExistsText,
          button: 'OK'
        };
        commit('SET_ACTION_MODAL', payload);
      }
      if (response?.status === 403) {
        let payload = {
          title: 'Error',
          text: serverErrorText,
          button: 'OK'
        };
        commit('SET_ACTION_MODAL', payload);
      }
    }
  },
  async update_user_status ({ commit }, user) {
    try {
      await axios.put(`${api.users}/${user.email}`, user);
      commit('UPDATE_USER_STATUS', user);
    } catch (err) {}
  },
  async delete_user ({ commit }, user) {
    try {
      await axios.delete(`${api.users}/${user.email}`, user);
      commit('DELETE_USER', user);
    } catch (err) {}
  }
};
