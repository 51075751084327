<template>
  <router-view />

  <img id="girl-reading" class="fixed" src="./assets/girl-reading.png" alt="" />
</template>

<style lang="scss">
  @media only screen and (min-width: 0) {
    #girl-reading {
      display: none;
    }
  }

  @media only screen and (min-width: 1024px) {
    #girl-reading {
      display: block;
      right: 30px;
      bottom: 30px;
      background-blend-mode: darken;
    }
  }
</style>
