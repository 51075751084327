<template>
  <label class="search-bar relative inline-block" :cy="cy || 'search-bar'">
    <input
      type="text"
      :id="name"
      v-model="string"
      placeholder="search book"
      @input="handleInput"
    />
    <i class="absolute">
      <img
        v-if="string"
        class="relative pointer"
        cy="clear-search"
        @click="clearSearch"
        src="../assets/small-x-icon.svg"
        alt="clear search"
      />
      <svg
        v-else
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6">
          <path
            d="M13.0333 11.4556C13.9466 10.2328 14.4414 8.74834 14.4444 7.22222C14.4444 5.7938 14.0209 4.39746 13.2273 3.20977C12.4337 2.02208 11.3057 1.09639 9.98605 0.549762C8.66636 0.00312928 7.21421 -0.139895 5.81324 0.138776C4.41226 0.417447 3.12539 1.1053 2.11534 2.11534C1.1053 3.12539 0.417447 4.41226 0.138776 5.81324C-0.139895 7.21421 0.00312928 8.66636 0.549762 9.98605C1.09639 11.3057 2.02208 12.4337 3.20977 13.2273C4.39746 14.0209 5.7938 14.4444 7.22222 14.4444C8.81111 14.4444 10.2667 13.9111 11.4556 13.0333L18.4333 20L20 18.4333L13.0333 11.4556ZM7.22222 12.2222C4.45556 12.2222 2.22222 9.98889 2.22222 7.22222C2.22222 4.45556 4.45556 2.22222 7.22222 2.22222C9.98889 2.22222 12.2222 4.45556 12.2222 7.22222C12.2222 9.98889 9.98889 12.2222 7.22222 12.2222Z"
            fill="black"
          />
          <path
            d="M8.10009 6.04446L7.22232 3.33335L6.34454 6.04446H3.61121L5.84454 7.81113L4.98898 10.5556L7.22232 8.85557L9.45565 10.5556L8.60009 7.81113L10.8334 6.04446H8.10009Z"
            fill="black"
          />
        </g>
      </svg>
    </i>
  </label>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    name: String
  });
  const emit = defineEmits(['search', 'clear']);
  const string = ref('');

  function handleInput () {
    emit('search', { name: props.name, value: string.value });
  }

  function clearSearch () {
    string.value = '';
    emit('clear', { name: props.name });
  }
</script>

<style lang="scss">
  @import '../styles/vars';

  .search-bar {
    input {
      width: 400px;
      padding: 6px 8px 6px 12px;
      border-radius: 4px;
      border: 1px solid $mid-yellow;
    }
    i img {
      top: 2px;
      right: 4px;
    }
    i {
      top: 8px;
      right: 10px;
    }
  }
</style>
