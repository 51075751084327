<template>
  <div class="base-audio-file flex flex-between" :cy="cy || name">
    <span>Page {{ page }}</span>
    <label v-if="status === 'upload'" :cy="`upload-${page}`" :for="name">
      <img class="pointer" src="../assets/upload-icon.svg" alt="upload" />
      <input :id="name" @change="handleUpload" type="file" />
    </label>

    <img
      v-if="status === 'uploading' && +selected === +page"
      cy="audio-load-spinner"
      class="pointer"
      src="../assets/spinner-icon.svg"
      alt="spinner"
    />

    <div v-if="status === 'success'" :cy="`audio-file-options-${page}`">
      <img
        v-if="playStatus === 'idle'"
        :cy="`play-icon-${page}`"
        class="m-r-16 pointer"
        @click="playAudio"
        src="../assets/play-icon.svg"
        alt="play"
      />
      <img
        v-if="playStatus === 'play'"
        :cy="`stop-icon-${page}`"
        class="m-r-16 pointer"
        @click="stopAudio"
        src="../assets/stop-icon.svg"
        alt="stop"
      />
      <img
        v-if="isFile"
        :cy="`delete-icon-${page}`"
        class="pointer"
        @click="confirmRemoveFile"
        src="../assets/delete-icon.svg"
        alt="delete"
      />
    </div>

    <audio :id="`audio-${name}`">
      <source :src="value ? `/files/${id}/${value}` : ''" type="audio/wav" />
    </audio>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useStore } from 'vuex';

  defineExpose({ removeFile });
  const { state, dispatch, commit } = useStore();
  const props = defineProps({
    name: String,
    value: String,
    selected: String,
    id: String,
    page: Number
  });
  const emits = defineEmits(['upload', 'remove']);
  const status = ref(props.value ? 'success' : 'upload');
  const isFile = ref(props.value);
  const playStatus = ref(props.value ? 'idle' : '');
  /* i want to have a custom status for each audio bar in order to show different UI states */

  function setStatus (value) {
    status.value = value;
  }

  function handleUpload (ev) {
    status.value = 'uploading';
    const audioPlayer = document.getElementById(`audio-${props.name}`);
    const file = ev.target.files[0];
    emits('upload', { name: props.name, file, page: props.page, setStatus });
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        audioPlayer.src = e.target.result;
      };
      reader.readAsDataURL(file);
      isFile.value = true;
      playStatus.value = 'idle';
    }
  }

  function confirmRemoveFile () {
    emits('remove', { name: props.name, page: props.page, setStatus });
  }

  function removeFile () {
    const audioPlayer = document.getElementById(`audio-${props.name}`);
    audioPlayer.src = '';
    isFile.value = false;
    playStatus.value = '';
  }

  function playAudio () {
    const audioPlayer = document.getElementById(`audio-${props.name}`);
    audioPlayer.play();
    playStatus.value = 'play';
  }

  function stopAudio () {
    const audioPlayer = document.getElementById(`audio-${props.name}`);
    audioPlayer.pause();
    audioPlayer.currentTime = 0;
    playStatus.value = 'idle';
  }
</script>

<style lang="scss">
  .base-audio-file {
    @media only screen and (min-width: 0) {
      border: 1px solid gray;
      padding: 8px;
      border-radius: 4px;
      input {
        visibility: hidden;
        width: 0;
        height: 0.1px;
      }
    }
  }
</style>
