import axios from 'axios';
import api from '@/store/modules/api';
import router from '@/router';

export const state = function () {
  return {
    data: [],
    dataCopy: [],
    status: 'idle',
    book: {
      slides: [],
      audio: {}
    },
    bookId: ''
  };
};

export const mutations = {
  SET_BOOKS_STATE (state, { key, value }) {
    state[key] = value;
  },
  SET_BOOK_PROP (state, { key, value }) {
    state.book[key] = value;
  },
  SEARCH_BOOK (state, string) {
    if (!state.dataCopy.length) {
      state.dataCopy = state.data.map((o) => o);
    }
    state.data = state.dataCopy.filter(
      ({ title, author }) =>
        title.toLowerCase().includes(string?.toLowerCase()) ||
        author.toLowerCase().includes(string?.toLowerCase())
    );
    if (!string) {
      state.data = state.dataCopy.slice();
      state.dataCopy = [];
    }
  },
  CLEAR_SEARCH (state) {
    state.data = state.dataCopy.slice();
  },
  ADD_BOOK (state, book) {
    state.data.push(book);
    state.dataCopy = state.data.slice(state.data);
  },
  DELETE_BOOK (state, id) {
    const index = state.data.findIndex(o => o._id === id);
    state.data.splice(index, 1);
  },
  UPDATE_BOOK_FIELD (state, { name, value }) {
    state.book[name] = value;
  },
  UPDATE_BOOK_DETAILS (state, { id }) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data[index] = { ...state.book };
    state.book = {
      slides: [],
      audio: {}
    };
  }
};

export const actions = {
  async get_books ({ commit }) {
    try {
      commit('SET_BOOKS_STATE', { key: 'status', value: 'fetching' });
      const { data } = await axios.get(api.books);
      commit('SET_BOOKS_STATE', { key: 'data', value: data });
      commit('SET_BOOKS_STATE', { key: 'status', value: 'idle' });
    } catch (err) {
      commit('SET_BOOKS_STATE', { key: 'status', value: 'error' });
    }
  },
  async get_book ({ commit }, id) {
    try {
      const { data } = await axios.get(`${api.books}/${id}`);
      commit('SET_BOOKS_STATE', { key: 'book', value: data });
    } catch (err) {}
  },
  async upload_book ({ commit }, { book, formData }) {
    try {
      commit('SET_BOOKS_STATE', { key: 'status', value: 'uploading' });
      Object.keys(book).forEach((key) => {
        formData.append(key, book[key]);
      });
      const { data } = await axios.post(api.books, formData);
      commit('ADD_BOOK', { ...book, ...data });
      commit('SET_BOOKS_STATE', { key: 'status', value: 'success' });
      commit('SET_BOOKS_STATE', { key: 'bookId', value: data._id });
    } catch (err) {
      commit('SET_BOOKS_STATE', { key: 'status', value: 'error' });
    }
  },
  async update_details ({ state, commit }, { id, setCurrentSection }) {
    try {
      const formData = new FormData();
      const fields = ['title', 'author', 'description'];
      fields.forEach((key) => {
        formData.append(key, state.book[key]);
      });
      commit('SET_BOOKS_STATE', { key: 'status', value: 'uploading' });
      await axios.put(`${api.books}/${id}`, formData);
      commit('UPDATE_BOOK_DETAILS', { id });
      commit('SET_BOOKS_STATE', { key: 'status', value: 'idle' });
      setCurrentSection('');
    } catch (err) {
      console.log(err);
      commit('SET_BOOKS_STATE', { key: 'status', value: 'error' });
    }
  },
  async upload_audio ({ commit }, { id, formData, page, setStatus }) {
    try {
      await axios.put(`${api.books}/${id}/${page}`, formData);
      // we are passing a component state function to have control on each audio bar status
      setStatus('success');
    } catch (err) {
      const payload = {
        type: 'notification',
        title: 'Error uploading file',
        text:
          err.response?.status === 415
            ? 'File format should be .mp3 or .wav'
            : err.response?.data
      };
      commit('SET_ACTION_MODAL', payload);
      commit('SET_MODAL', true);
      setStatus('upload');
    }
  },
  async delete_audio_file ({ commit }, { id, page, setStatus }) {
    try {
      const url = `${api.books}/${id}/${page}`;
      await axios.delete(url);
      setStatus('upload');
    } catch (err) {
      setStatus('upload');
    }
  },
  async delete_book ({ commit }, { id }) {
    try {
      commit('SET_BOOKS_STATE', { key: 'status', value: 'uploading' });
      const url = `${api.books}/${id}`;
      await axios.delete(url);
      commit('DELETE_BOOK', id);
      commit('SET_BOOKS_STATE', { key: 'status', value: 'idle' });
      await router.push('/dashboard/books');
    } catch (err) {
      console.log(err);
      commit('SET_BOOKS_STATE', { key: 'status', value: 'error' });
    }
  }
};
