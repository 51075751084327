import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      { path: '', redirect: '/books' },
      {
        path: '/books',
        name: 'books',
        meta: { auth: true, roles: ['admin', 'user'] },
        component: () => import('../views/BooksList.vue')
      },
      {
        path: '/books/:id',
        name: 'book-preview',
        meta: { auth: true, roles: ['admin', 'user'] },
        component: () => import('../views/BookPreview.vue')
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => import('../layouts/DashboardLayout.vue'),
    children: [
      { path: '', redirect: '/dashboard/books' },
      {
        path: 'users',
        name: 'users',
        meta: { auth: true, roles: ['admin'] },
        component: () => import('../views/UsersList.vue')
      },
      {
        path: 'books',
        name: 'books-admin-layout',
        component: () => import('../layouts/BooksLayout.vue'),
        children: [
          {
            path: '',
            name: 'books-list-admin',
            meta: { auth: true, roles: ['admin'] },
            component: () => import('../views/BooksListAdmin.vue')
          },
          {
            path: 'upload',
            name: 'books-admin-upload',
            meta: { auth: true, roles: ['admin'] },
            component: () => import('../views/UploadBook.vue')
          },
          {
            path: 'edit/:id',
            name: 'books-admin-edit',
            meta: { auth: true, roles: ['admin'] },
            component: () => import('../views/EditBook.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes
});

router.beforeEach(async ({ params, meta, name: route }, from, next) => {
  if (route === 'login') {
    return next();
  }
  meta?.auth && !store.state.auth.user?.name
    ? await store.dispatch('get_user', { roles: meta.roles, next })
    : next();
});

export default router;
