<template>
  <div class="base-confirmation-modal" cy="base-confirmation">
    <h2 v-if="title">{{ title }}</h2>
    <p>{{ msg }}</p>
    <div v-if="type === 'notification'">
      <button cy="confirmation-ok" @click="handleAction('no')">OK</button>
    </div>
    <div v-else class="action-btns flex m-t-16">
      <button class="empty-btn m-r-16 m-left-auto" cy="confirmation-no" @click="handleAction('no')">No</button>
      <button class="green-btn" cy="confirmation-yes" @click="handleAction('yes')">Yes</button>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    title: String,
    msg: String,
    type: String
  });

  const emits = defineEmits(['confirm']);

  function handleAction (action) {
    emits('confirm', { action });
  }
</script>

<style lang="scss">
  .base-confirmation-modal {
    @media only screen and (min-width: 0) {
    	.action-btns {
        button {
          width: 120px;
        }
      }
    }
  }
</style>
