<template>
  <div class="base-table" :cy="cy || 'base-table'">
    <ul cy="table-header" class="table-header flex flex-between">
      <li :class="`${prop}-column`" v-for="({ name, prop }, index) in header" :key="index">
        {{ name }}
      </li>
    </ul>

    <ul
      cy="table-body"
      class="table-row flex flex-between pointer"
      @click="handleClick(item)"
      v-for="(item, index) in data"
      :key="index"
    >
      <li :class="`${prop}-column`" v-for="({ name, prop, slot }, index) in header" :key="index">
        <slot v-if="slot" :audio="item[prop]" :item="item" />
        <span v-else>{{ item[prop] }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    cy: String,
    header: Array,
    data: Array
  });

  const emits = defineEmits(['click']);

  function handleClick (item) {
    emits('click', { name: props.name, item });
  }
</script>

<style lang="scss">
  .base-table {
    @media only screen and (min-width: 0) {
      .table-header,
      .table-row {
        padding: 0 8px;
      }
      .table-header {
        border-bottom: 1px solid lightgray;
        li {
          font-size: 2rem;
        }
      }
      .table-row {
        li {
          padding: 4px 0;
          span {
            font-size: 2.2rem;
          }
        }
        &:hover {
          background-color: #ececec;
        }
      }
    }
  }
</style>
